<template>
  <v-dialog v-model="dialog" persistent :max-width="1200" :style="{ height: '95%' }">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2 d-flex align-center">
        <span>Monthly Inverter Adjustment Factors</span>
        <v-spacer></v-spacer>
        <v-progress-circular
          v-if="isLoading"
          indeterminate
          color="primary"
          size="24"
          width="3"
          class="ml-3"
        ></v-progress-circular>
      </v-card-title>
      <v-card-text v-if="!showConfirmation">
        <Grid
          ref="grid"
          :data-items="gridData"
          :edit-field="'inEdit'"
          @itemchange="itemChange"
          @rowclick="enableEditing"
          @blur="disableEditing"
          :pageable="pageable"
          :take="take"
          :skip="skip"
          :page-size="pageSize"
          :total="total"
          :columns="columns"
          @pagechange="pageChange"
        >
          <template v-slot:loadDataCell="{ props }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  :class="{
                    'font-weight-bold text-xs btn-success bg-gradient-success': hasAdjustmentData(props.dataItem.month),
                    'font-weight-bold text-xs btn-danger bg-gradient-danger': !hasAdjustmentData(props.dataItem.month)
                  }"
                  style="
                    min-width: 40px; 
                    height: 30px; 
                    font-size: 12px; 
                    text-align: center; 
                    line-height: 1.5;
                    margin-top: 10px;
                    margin-left: 10px;
                    pointer-events: auto;
                  "
                  @click="hasAdjustmentData(props.dataItem.month) ? loadAdjustmentFactorsForMonth(props.dataItem.month) : null"
                >
                  <template v-if="hasAdjustmentData(props.dataItem.month)">
                    ✔
                  </template>
                  <template v-else>
                    ✘
                  </template>
                </v-btn>
              </template>
              <span>
                {{ hasAdjustmentData(props.dataItem.month) ? 'Data available for this month' : 'No data available' }}
              </span>
            </v-tooltip>
          </template>
          <!-- <template v-slot:loadDataCell="{ props }">
            <v-btn>load</v-btn>
          </template>
          <template v-slot:saveButtonCell="{ props }">
            <v-btn>Save</v-btn>
          </template> -->
          <!-- <grid-norecords>No data available</grid-norecords> -->
        </Grid>
      </v-card-text>
      <v-card-text v-else>
        <p>
          Do you want to queue the inverter estimate recast for the past 12 months before closing? 
          This process may take up to 5 minutes to complete.
        </p>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <template v-if="showConfirmation">
          <v-btn color="red" text @click="confirmRecast">Yes, Recast</v-btn>
          <v-btn color="grey" text @click="cancelRecast">No, Just Close</v-btn>
        </template>
        <template v-else>
          <v-btn
            class="font-weight-bold text-xs btn-success bg-gradient-success"
            :disabled="isLoading"
            @click="handleReload"
          >
            Reload
          </v-btn>
          <v-btn
            class="font-weight-bold text-xs btn-success bg-gradient-success"
            :disabled="isLoading"
            @click="disableEditing"
          >
            Save
          </v-btn>
          <v-btn
            class="font-weight-bold text-xs btn-danger bg-gradient-danger"
            :disabled="isLoading"
            @click="promptConfirmation"
          >
            Close
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
import Vue from "vue";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";
import Service from "@/services/Service.js";

Vue.use(VueToast);

export default {
  components: {
    Grid,
    "grid-norecords": GridNoRecords,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    inverterData: {
      type: Array,
      required: true,
    },
    siteId: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      dialog: this.value,
      showConfirmation: false,
      pageable: { pageSizes: [12] },
      skip: 0,
      take: 12,
      pageSize: 12,
      gridData: [],
      isLoading: false,
      adjustmentData: [],
    };
  },
  computed: {
    total() {
      return this.gridData.length;
    },
    columns() {
      return [
        {
          field: "load",
          title: "Load Data",
          width: "110px",
          cell: "loadDataCell",
        },
        
        { field: "month", title: "Month", width: "120px", editable: false },
        ...this.inverterData.map((inverter) => ({
          field: `inv${inverter.id}`,
          title: inverter.name,
          editor: "numeric",
          editable: true,
          width: "150px",
        })),
        // { cell: "saveButtonCell", filterable: false, width: "100px" },
      ];
    },
  },
  created() {
    this.initializeGridData();
  },
  watch: {
    inverterData: {
      immediate: true, // React to initial value
      handler(newData) {
        console.log("Parent data changed, updating child...");
        this.gridData = [...newData]; // Update local data in the child
        this.initializeGridData();
      },
    },
    adjustmentData(newValue) {
      // console.log(`adjustment data changed ${newValue}`);
      // ✅ Force Vue to update UI when adjustmentData changes
      this.gridData = [...this.gridData];
    },
    value(newValue) {
      this.dialog = newValue;
    },
    dialog(newValue) {
      // When the popup opens, fetch adjustment factors
      if (newValue) {
        this.loadAdjustmentFactors();
      }
      this.$emit("input", newValue);
    },
  },
  methods: {
    hasAdjustmentData(month) {
      if (!this.adjustmentData || this.adjustmentData.length === 0) {
        // console.log(`[DEBUG] No adjustment data available.`);
        return false;
      }

      // ✅ Convert Month Name to Number If Needed
      const monthNumber = this.getMonthNumber(month);

      // console.log(`[DEBUG] Checking for month ${monthNumber} in adjustmentData...`);

      const found = this.adjustmentData.some((d) => {
        // console.log(`[DEBUG] Comparing: Data Month = ${Number(d.month)}, Lookup Month = ${monthNumber}`);
        return Number(d.month) === monthNumber;
      });

      // console.log(`[DEBUG] Result for month ${monthNumber}: ${found ? "✅ Found" : "❌ Not Found"}`);
      return found;
    },
    loadAdjustmentFactorsForMonth(month) {
      const monthNumber = this.getMonthNumber(month);

      if (isNaN(monthNumber)) {
        console.error(`[ERROR] Invalid month value received: ${month}`);
        return;
      }

      console.log(`[DEBUG] Loading adjustment factors for month: ${monthNumber}`);

      const adjustments = this.adjustmentData?.filter((d) => Number(d.month) === monthNumber);

      if (adjustments.length > 0) {
        this.gridData = this.gridData.map((row) => {
          if (this.getMonthNumber(row.month) === monthNumber) {
            adjustments.forEach((adjustment) => {
              const field = `inv${adjustment.inverter_id}`;
              const newValue = Number(adjustment.adjustment_factor).toFixed(3); // ✅ Truncate to 4 decimals
              row[field] = Number(newValue);
              // row[`inv${adjustment.inverter_id}`] = Number(adjustment.adjustment_factor); // ✅ Ensure numeric value
            });
          }
          return row;
        });

        Vue.$toast.success(`Loaded data for ${this.getMonthName(monthNumber)}`, { position: "top-right" });
      } else {
        Vue.$toast.error(`No data found for ${this.getMonthName(monthNumber)}`, { position: "top-right" });
      }
    },
    getMonthNumber(month) {
      // ✅ Convert Month Name to Number
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      if (typeof month === "string") {
        const index = monthNames.indexOf(month);
        return index !== -1 ? index + 1 : NaN; // Convert to number (Jan = 1, Dec = 12)
      }

      return Number(month); // Already a number, return as is
    },
    initializeGridData() {
      // Generate rows for each month (1–12) with associated factors
      this.gridData = Array.from({ length: 12 }, (_, i) => {
        const monthIndex = i + 1;
        const row = {
          month: this.getMonthName(monthIndex),
          inEdit: false, // Needed to allow inline editing
        };
        // const row = {
        //   load: true, // Needed for Load Data column
        //   save: true, // Needed for Save column
        //   month: this.getMonthName(monthIndex),
        //   inEdit: false, // Tracks edit state
        // };
        this.inverterData.forEach((inverter) => {
          const factor = inverter.factors.find((f) => f.month === monthIndex);
          row[`inv${inverter.id}`] = factor ? factor.factor : null;
        });
        return row;
      });
    },
    /**
     * ✅ Checks if a row is in edit mode
     */
    isRowInEditMode(row) {
      let flag = row.inEdit === true;
      console.log(`[DEBUG] ${flag} Checking edit mode for row:`, row);
      return flag;
    },
    handleReload() {
      // Emit an event to the parent
      this.$emit("reload-data");
      // Optionally, you can show a toast for user feedback
      Vue.$toast.success("Reloading data...", { position: "top-right" });
    },
    /**
     * ✅ Saves all inverter values for a given row
     */
    saveRow(row) {
      if (!this.isRowInEditMode(row)) {
        Vue.$toast.warning("Row is not in edit mode.", { position: "top-right" });
        return;
      }
      this.isLoading = true; // Start loading state

      try {
        const month = this.getMonthNumber(row.month);

        // 🚀 Fire all update requests in parallel (no need to wait)
        Object.keys(row).forEach((key) => {
          if (key.startsWith("inv")) {
            const inverterId = key.replace("inv", "");
            const value = row[key];

            if (value !== null && value !== undefined) {
              this.updateFactor(inverterId, month, value); // 🔥 Fires request but does NOT wait
            }
          }
        });

        // ✅ Immediately update UI without waiting for responses
        this.gridData = this.gridData.map((r) =>
          r === row ? { ...r, inEdit: false } : r
        );

        Vue.$toast.success(`Factors saved for ${row.month}!`, { position: "top-right" });

      } catch (error) {
        console.error("[ERROR] Failed to save inverter values:", error);
        Vue.$toast.error("Error saving inverter factors. Please try again.", { position: "top-right" });

      } finally {
        this.isLoading = false; // ✅ Stop loading even if requests are still pending
      }
    },
    loadAdjustmentFactors() {
      //debugger;
      console.log('loadAdjustmentFactors');
      this.isLoading = true;
      Service.generateFactors(this.siteId)
        .then((response) => {
          this.adjustmentData = response.data.data; // Assuming response has { data: [...] }
          // ✅ Force UI refresh to update buttons
          this.gridData = [...this.gridData];

          Vue.$toast.success("Adjustment factors loaded successfully!", {
            position: "top-right",
          });
        })
        .catch(() => {
          Vue.$toast.error("Error loading adjustment factors.", {
            position: "top-right",
          });
        })
        .finally(() => {
          this.isLoading = false; // ✅ Hide spinner after data loads
        });
    },
    disableEditing() {
      //debugger;
      const editedRow = this.gridData.find((row) => row.inEdit); // ✅ Find the row being edited

      if (!editedRow) {
        Vue.$toast.warning("No row is currently being edited.", { position: "top-right" });
        return;
      }

      const month = this.getMonthNumber(editedRow.month);

      Object.keys(editedRow).forEach((key) => {
        if (key.startsWith("inv")) {
          const inverterId = key.replace("inv", "");
          const value = editedRow[key];

          if (value !== null && value !== undefined) {
            // ✅ Call updateFactor() to save the value
            this.updateFactor(inverterId, month, value);
          }
        }
      });

      // ✅ Disable editing only for the saved row
      this.gridData = this.gridData.map((row) =>
        row === editedRow ? { ...row, inEdit: false } : row
      );

      Vue.$toast.success(`Factors saved for ${editedRow.month}!`, { position: "top-right" });
    },
    getMonthName(month) {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      return monthNames[month - 1];
    },
    /**
     * Enables editing mode when clicking a cell.
     */
    enableEditing(event) {
      const { dataItem } = event;

      console.log(`[DEBUG] Enabling edit mode for:`, dataItem);
      // Set `inEdit` to true for the clicked row
      this.gridData = this.gridData.map((item) =>
        item.month === dataItem.month ? { ...item, inEdit: true } : { ...item, inEdit: false }
      );
    },
    itemChange(event) {
      const { field, value, dataItem } = event;

      // Validate numeric input
      if (isNaN(value) || value < 0) {
        Vue.$toast.error("Please enter a valid positive number.", { position: "top-right" });
        return;
      }

      // Find and update the grid data
      const updatedData = this.gridData.slice();
      const index = updatedData.findIndex((item) => item.month === dataItem.month);
      if (index >= 0) {
        updatedData[index][field] = value;
        this.gridData = updatedData;

        // Extract inverter ID and month
        const inverterId = field.replace("inv", "");
        const month = dataItem.month;

        // Update the backend
        this.updateFactor(inverterId, month, value);
      }
      // ✅ Automatically disable editing when done
      //this.disableEditing();
    },
    updateFactor(inverterId, month, value) {
      const params = { month, factor: value };

      Service.updateInverterFactor(inverterId, params)
        .then(() => {
          Vue.$toast.success(`Factor updated for ${month}`, { position: "top-right" });
        })
        .catch(() => {
          Vue.$toast.error("Error updating factor.", { position: "top-right" });
        });
    },
    pageChange(event) {
      this.skip = event.page.skip;
      this.take = event.page.take;
    },
    promptConfirmation() {
      this.showConfirmation = true;
    },
    confirmRecast() {
      const siteId = this.siteId;

      Service.recastInverterEstimates(siteId)
        .then((response) => {
          Vue.$toast.success(
            "Recast process has been queued successfully. Please wait approximately 5 minutes for the updates to reflect.",
            {
              position: "top-right",
            }
          );
          console.log("Recast response:", response.data);
        })
        .catch((error) => {
          Vue.$toast.error("Error recasting inverter estimates.", {
            position: "top-right",
          });
          console.error("Recast error:", error);
        })
        .finally(() => {
          this.showConfirmation = false; // Hide the confirmation prompt
          this.dialog = false; // Close the dialog
        });
    },
    cancelRecast() {
      this.showConfirmation = false;
      this.dialog = false;
    },
  },
};
</script>
